import Banner from "../components/Banner";
import CallForProjects from "../components/CallForProjects";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Introduction from "../components/Introduction";
import Services from "../components/Services";
import Policy from "../components/Policy";
import ITBrief from "../components/ITBrief";
import ContactRibbon from "../components/ContactRibbon";
import Partners from "../components/Partners";

import MechanicBrief from "../components/MechanicBrief";

function Home() {
  return (
    <div className="Home">
      <Header />
      <Banner />
      <Introduction />
      <ContactRibbon />
      <Services  />
      <Policy />
      <ITBrief />
      <CallForProjects />
      <MechanicBrief/>
      <Partners />
      <Footer />
    </div>
  );
}

export default Home;
