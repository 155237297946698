import backgroundImage from "../public/images/backgrounds/map.png";

const Banner = ({title}) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  return (
    <>
      <div
        style={backgroundStyle}
        className="sticky top-0 -z-50 h-[250px] lg:h-[350px] py-8  duration-700  flex items-center ease-in bg-gray-200 bg-no-repeat bg-center bg-cover "
      >
        <div className=" w-full  text-center  text-blue-600 duration-300">
          <h1 className="lg:px-[20%]  text-2xl md:text-4xl font-semibold italic ">
            {title}
          </h1>
          <p className=" text-blue-400">home / services / {title}</p>
        </div>
      </div>
    </>
  );
};

export default Banner;
