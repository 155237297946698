import React from "react";
import logo from "../public/images/logo7.png";


const Logo = (props) => {
   
  return (
    <>
        <div className=" rounded-lg bg-gradient-to-b from-blue-600 via-blue-700 blue-900  w-[80px] h-[120px] ">
        <div className="rounded-lg bg-tranparent  p-2 -mt-3 w-[80px] h-[120px] flex justify-center items-center ">
        <img
          src={logo}
          alt="Brand"
          className="w-[px] h-[48px] "
        />
        </div>

        </div>
    </>
  );
};

export default Logo;
