import React from "react";
import InfoCard from "./InfoCard";
import backgroundImage from "../public/images/backgrounds/bgn-industries.png";
import Title from "./Title";

import { useInView } from "react-intersection-observer";

const AnimatedElement = ({ children, direction = "left" }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animationClass = inView
    ? `slide-in-${direction}`
    : `slide-out-${direction}`;

  return (
    <div ref={ref} className={animationClass}>
      {children}
    </div>
  );
};

const Introduction = ({ title,data,intro}) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

 

  return (
    <>
      <div
        style={backgroundStyle}
        className="px-4 py-16 lg:px-8 lg:py-32 text-slate-900 bg-white"
      >
        <div className="w-full ">
          <AnimatedElement direction="left">
            <Title title={title} />
          </AnimatedElement>
          <AnimatedElement direction="bottom">
            <div className="text-center">
              <h2 className="text-[24px] lg:text-[38px] font-extrabold italic mb-8">
                We provide the best{" "}
                <span className="text-blue-600 font-bold">{title} Services</span>
              </h2>
              <p className="text-justify text:sm md:text-lg text-gray-700 mb-8">
               {intro}
              </p>
            </div>
          </AnimatedElement>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-16 justify-items-center">
    {data.map((item, index) => (
        <AnimatedElement key={index} direction={item.direction}>
            <InfoCard
                icon={item.icon}
                title={item.title}
                brief={item.brief}
            />
        </AnimatedElement>
    ))}
</div>
      </div>
    </>
  );
};

export default Introduction;
