import Banner2 from "../components/Banner2";
import CallForProjects from "../components/CallForProjects";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Partners from "../components/Partners";
import Projects from "../components/Projects";


function Electrical() {

  return (
    <div className="IT">
      <Header />
      <Banner2 title={"Projects"} />
      <Projects/>
      <CallForProjects />
      <Partners />
      <Footer />
    </div>
  );
}

export default Electrical;
