import React from 'react';


// Individual Service Card Component
const ServiceCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white rounded-lg p-6 shadow-md max-w-sm mx-auto my-2">
      <div className="h-16 mx-auto mb-4" >{icon}</div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 text-sm">{description}</p>
    </div>
  );
};

// Services Section Component
const ServicesSection = ({services,title}) => {
  // Sample data for services
 

  return (
    <section className="bg-gray-100 py-12">
    <div className="text-center mb-8">
      <h2 className="text-xl lg:text-3xl font-bold mb-2">We Are Offering All Kinds of {title} Solutions Services</h2>
      <div className="inline-block w-16 h-1 bg-blue-500 mx-auto"></div>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  px-4">
      {services.map((service, index) => (
        <ServiceCard
          key={index}
          icon={service.icon}
          title={service.title}
          description={service.description}
        />
      ))}
    </div>
  </section>
  
  
  
  );
};

export default ServicesSection;
