import entrance from "../public/images/Banner/Entrance.jpeg"
import it from "../public/images/Banner/CCTV.jpeg"
import electrical from "../public/images/Banner/Workshop.jpeg"
export const BannerInfo = [
    {
        id: 1,
        title: "Learn More About Us",
        brief: "Discover our journey, values, and the dedicated team that makes it all happen at Ideal Choice, your trusted partner in progress.",
        cta: [{ label: "Learn More", url: "/" }],
        backgroundUrl: entrance
    },
    {
        id: 2,
        title: "Expert IT Services",
        brief: "Providing cutting-edge IT solutions, from cloud services to cybersecurity, ensuring seamless digital experiences for our clients.",
        cta: [{ label: "Explore IT Services", url: "/" }],
        backgroundUrl: it
    },
    {
        id: 3,
        title: "Professional Electrical Services",
        brief: "Our skilled electricians offer top-notch installation, maintenance, and repair services, powering your projects safely and efficiently.",
        cta: [{ label: "Our Electrical Services", url: "/" }],
        backgroundUrl: electrical
    }
];