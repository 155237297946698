import React from 'react';

const CertificationInfo = () => {
  return (
    <div className="bg-blue-600 p-4 flex items-center justify-between text-white mt-16 p-8">
      <div className="flex items-center">
        <ul className="list-disc pl-5 space-y-2">
          <li>Registration certificate from Public Procurement & Assets Disposal Board (PPADB) Grade E (Unlimited) Electrical Installation.</li>
          <li>Botswana Unified Revenue Services (BURS) registered and valid.</li>
          <li>A trading License from South East District council (SEDC) valid</li>
          <li>All the above can be attached or provided on request.</li>
        </ul>
      </div>
    
    </div>
  );
};

export default CertificationInfo;
