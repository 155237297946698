
const Card = ({svg,title,brief}) => {
  return (
    <>
  <div className="h-[450px] bg-white shadow-lg shadow-blue-100 rounded-lg px-4 py-8 hover:bg-blue-600 duration-700 hover:text-white">
    <div className="flex justify-center mb-2">
      <img
        src={svg}
        alt="some"
        className="w-48 h-48 bg-blue-100 rounded-full p-1"
      />
    </div>
    <h3 className="text-3xl font-semibold text-center mb-2">{title}</h3>
    <p className="text-sm text-center text-justify lg:text-center">
     {brief}
    </p>
  </div>

    </>
  );
};

export default Card;
