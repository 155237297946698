import image from "../public/images/services/electrical/Substation2.jpeg";
import image2 from "../public/images/services/electrical/team.jpeg";
import image3 from "../public/images/services/electrical/Breaker.jpeg";
import image4 from "../public/images/services/electrical/river.jpeg";

import ListItem from "./ListItem";
import backgroundImage from "../public/images/backgrounds/bgn-industries.png";

const Policy = (props) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  return (
    <>
      <div
        style={backgroundStyle}
        className="grid grid-cols-12 gap-4 lg:gap-8 py-8 px-8 lg:p-16"
      >
        <div className="col-span-12 lg:col-span-7 lg:mt-16">
          <div className="font-semibold text-xl md:text-3xl  capitalize text-slate-800">
            Benefit of our{" "}
            <span className="text-blue-600">Electrical services</span> {""}
            Provided by Ideal Choice
          </div>
          <p className="text-md mt-2 text-slate-800 md:pr-16 text-justify ">
            We specialize in Electrical (General) design, installations, and
            maintenance. Ideal Choice provides services to small, medium, and
            large companies. Our team consists of qualified and skilled
            Technicians and Engineers trained in their respective fields.
          </p>
        
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-6 py-8 text-slate-700">
            <div className="">
              <ListItem
                title="Value for the Customer and Complete Satisfaction"
                text="Emphasizing high-quality electrical services that exceed customer expectations, focusing on reliability, safety, and personalized service."
              />
            </div>

            <div className="">
              <ListItem
                title="Best Practice Industrial Methodologies for Calibration Results"
                text="Commitment to accuracy and traceability in electrical calibration, ensuring consistent and reliable results in line with industry best practices."
              />
            </div>

            <div className="">
              <ListItem
                title="Well Trained and Competent Personnel"
                text="Ensuring all staff are skilled and knowledgeable, with continuous training in the latest electrical standards and technologies for efficient and effective solutions."
              />
            </div>

            <div className="">
              <ListItem
                title="Sustainable Business and Continuous Improvement in Quality System"
                text="Focusing on sustainability and continuous quality improvement, updating practices, tools, and techniques to stay current with advancements and standards."
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 col-span-12 h-[100px] px-4  lg:p-8 lg:h-[600px]  rounded-lg w-full lg:col-span-5 flex justify-center lg:justify-start">
          <div
            className="col-span-4 lg:col-span-7  h-[100px]  w-full lg:h-full rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div
            className="col-span-4  lg:col-span-5  h-[100px]  w-full lg:h-full   rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image2})` }}
          ></div>

          <div
            className="col-span-4 lg:col-span-4  h-[100px]  w-full lg:h-full  rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image3})` }}
          ></div>
          <div
            className="hidden lg:block col-span-4  lg:col-span-8  h-[100px]  w-full lg:h-full   rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image4})` }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Policy;
