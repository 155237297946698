import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../public/images/logo7.png";
import { Link } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineProject,
  AiOutlineMail,
  AiOutlineBulb, // Represents electrical services
  AiOutlineLaptop, // Represents IT services
  AiOutlineTool, // Represents mechanical services
} from "react-icons/ai";
const TopNav = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isSideNavVisible, setIsSideNavVisible] = useState(false);

  return (
    <>
      <div className="bg-white flex items-center py-2 lg:py-8">
        <div className="flex items-center w-3/12 lg:w-2/12">
          <div
            onClick={() => setIsSideNavVisible(true)}
            className="flex mr-4 lg:hidden cursor-pointer"
          >
            <AiOutlineMenu size={25} />
          </div>
          <img src={logo} alt="Brand" className=" h-[48px] lg:hidden" />
        </div>

        <div className="hidden lg:flex justify-center w-7/12">
          <ul className="flex text-slate-700 font-medium text-[18px]">
            <li className="mx-3 hover:border-blue-300 cursor-pointer slide-border">
              <Link to="/">Home</Link>
            </li>

            <li
              className="mx-3 hover:border-blue-300 cursor-pointer slide-border relative"
              onMouseEnter={() => setIsDropdownVisible(true)}
              onMouseLeave={() => setIsDropdownVisible(false)}
            >
              Services
              {isDropdownVisible && (
                <div className="absolute left-0 mt-2 bg-white shadow-md border rounded-md">
                  <ul className="block w-48 text-sm">
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link to="/it">IT Services</Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link to="/electrical">Electrical Services</Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100">
                      <Link to="/mechanical">Mechanical Services</Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>

            <li className="mx-3 hover:border-blue-300 cursor-pointer slide-border">
              <Link to="/about">About us</Link>
            </li>
            <li className="mx-3 hover:border-blue-300 cursor-pointer slide-border">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="mx-3 hover:border-blue-300 cursor-pointer slide-border">
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>

      {isSideNavVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="fixed right-0 top-0 bottom-0 bg-white w-64 p-4">
            <AiOutlineClose
              size={25}
              className="cursor-pointer mb-4"
              onClick={() => setIsSideNavVisible(false)}
            />
            <ul className="flex flex-col">
              <li className="my-3">
                <Link
                  to="/"
                  className="flex items-center px-4 py-3 rounded hover:bg-blue-600 hover:text-white border-b border-gray-200"
                  onClick={() => setIsSideNavVisible(false)}
                >
                  <AiOutlineHome className="mr-3" />
                  Home
                </Link>
              </li>

              <li className="my-2">
                <Link
                  to="/electrical"
                  className="flex items-center px-4 py-3 rounded hover:bg-blue-600 hover:text-white border-b border-gray-200"
                  onClick={() => setIsSideNavVisible(false)}
                >
                  <AiOutlineBulb className="mr-3" />
                  Electrical Services
                </Link>
              </li>
              <li className="my-2">
                <Link
                  to="/it"
                  className="flex items-center px-4 py-3 rounded hover:bg-blue-600 hover:text-white border-b border-gray-200"
                  onClick={() => setIsSideNavVisible(false)}
                >
                  <AiOutlineLaptop className="mr-3" />
                  IT Services
                </Link>
              </li>
              <li className="my-2">
                <Link
                  to="/mechanical"
                  className="flex items-center px-4 py-3 rounded hover:bg-blue-600 hover:text-white border-b border-gray-200"
                  onClick={() => setIsSideNavVisible(false)}
                >
                  <AiOutlineTool className="mr-3" />
                  Mechanical Services
                </Link>
              </li>
              <li className="my-2">
                <Link
                  to="/about"
                  className="flex items-center px-4 py-3 rounded hover:bg-blue-600 hover:text-white border-b border-gray-200"
                  onClick={() => setIsSideNavVisible(false)}
                >
                  <AiOutlineUser className="mr-3" />
                  About us
                </Link>
              </li>
              <li className="my-2">
                <Link
                  to="/projects"
                  className="flex items-center px-4 py-3 rounded hover:bg-blue-600 hover:text-white border-b border-gray-200"
                  onClick={() => setIsSideNavVisible(false)}
                >
                  <AiOutlineProject className="mr-3" />
                  Projects
                </Link>
              </li>
              <li className="my-2">
                <Link
                  to="/contact"
                  className="flex items-center px-4 py-3 rounded hover:bg-blue-600 hover:text-white border-b border-gray-200"
                  onClick={() => setIsSideNavVisible(false)}
                >
                  <AiOutlineMail className="mr-3" />
                  Contact Us
                </Link>
              </li>
              {/* Add more links with icons as needed */}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default TopNav;
