import React from "react";
import {
  BsEnvelopeFill,
  BsFacebook,
  BsTelephoneFill,
  BsTwitter,
} from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";

const ContactHeader = () => {
  return (
    <>
      <div className="hidden md:grid grid-cols-12  font-medium  text-white">
        <div className="col-span-2  flex items-center w-full h-full   bg-blue-800  ">
          <div className="relative left-4  flex items-center w-full h-full skew-x-[24deg]  bg-blue-800   px-8 py-1"></div>
        </div>
        <div className="col-span-8  flex justify-center items-center w-full px-6 md:px-[48px] py-2 text-slate-800 bg-white  font-montserrat">
          <div className="flex items-center mr-8 mb-4 md:mb-0">
            <BsTelephoneFill className="text-blue-700 text-md mr-2" />
            <span className="font-normal">+267 318 1551</span>
          </div>
          <div className="flex items-center mr-8 mb-4 md:mb-0">
            <BsEnvelopeFill className="text-blue-700 text-md mr-2" />
            <span className="font-normal">sales@idealchoice.co.bw</span>
          </div>
          <div className="hidden lg:flex items-center mr-8 mb-4 md:mb-0">
            < MdLocationPin  className="text-blue-700 text-md mr-2" />
            <span className="font-normal">
              Plot 7737,Selokwana Industrial Site,Tlokweng
            </span>
          </div>
        
        </div>
        <div className="col-span-2 flex justify-end items-center w-full  text-slate-800 bg-white  font-montserrat">
          
          <div className="flex items-center justify-end mr-16 space-x-4">
            <div className="text-gray-600 cursor-pointer hover:text-blue-700">
              <BsFacebook size={20} />
            </div>
            <div className="text-gray-600 cursor-pointer hover:text-blue-700">
              <BsTwitter size={20} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactHeader;
