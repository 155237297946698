import React from "react";
import InfoCard from "./InfoCard";
import backgroundImage from "../public/images/backgrounds/bgn-industries.png";
import Title from "./Title";
import { MdElectricBolt, MdBuild, MdComputer } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import ListItem from "./ListItem";
import Vision from "./Vision";
import CertificationInfo from "./CertInfo";

const AnimatedElement = ({ children, direction = "left" }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animationClass = inView
    ? `slide-in-${direction}`
    : `slide-out-${direction}`;

  return (
    <div ref={ref} className={animationClass}>
      {children}
    </div>
  );
};

const Introduction = ({ vector }) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <>
      <div
        style={backgroundStyle}
        className="px-8 lg:px-8 py-16 lg:py-32 text-slate-900 bg-gradient-to-b from-slate-100"
      >
        <div className="w-full ">
          <AnimatedElement direction="left">
            <Title title={"Introduction"} />
          </AnimatedElement>
          <AnimatedElement direction="bottom">
            <div className="text-left">
              <h2 className="text-[32px] md:text-[58px] font-extrabold italic mb-8">
                Success Through Strategic{" "}
                <span className="text-blue-600 font-bold">Partnerships</span>
              </h2>
              <p className="text-md md:text-lg text-gray-700 mb-8 ">
                Ideal Choice (Pty) Ltd is a 100% Citizen-owned company
                specializing in Information Technology Services and Electrical
                Services. Our philosophy and strategy revolve around "success
                through strategic partnerships." We have forged key
                international partnerships, providing us with the necessary
                technical and logistical capabilities to excel in medium to
                large-scale ICT implementations and electrical solutions.
              </p>
            </div>
          </AnimatedElement>
        </div>
        <div className="block md:flex justify-center items-center md:mt-16 gap-4">
          <AnimatedElement direction="left">
            <InfoCard
              icon={<MdElectricBolt size={50} />}
              title="Electrical Services"
              brief="Ensuring reliable and safe electrical systems operation."
            />
          </AnimatedElement>
          <AnimatedElement direction="bottom">
            <InfoCard
              icon={<MdBuild size={50} />}
              title="Mechanical Services"
              brief="Providing expert mechanical engineering solutions and services."
            />
          </AnimatedElement>
          <AnimatedElement direction="right">
            <InfoCard
              icon={<MdComputer size={50} />}
              title="Information Technology"
              brief="Delivering cutting-edge IT solutions and support for businesses."
            />
          </AnimatedElement>
        </div>
        {/* New Content - Vision & Mission */}
        <Vision />

        {/* New Content - Services */}
        <div className="mt-16 px-4 lg:px-48">
          <AnimatedElement direction="bottom">
            <Title
              title={"SERVICES"}
              className="text-3xl lg:text-4xl font-bold text-center mb-8"
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <ListItem
                title="PROJECT PLANNING"
                text="Ideal Choice (Pty) Ltd engineers and management planning services evaluate your existing strategy and architecture, based on our experience and industry-best practices, identify gaps and makes recommendations to help your business objectives."
                className="shadow-lg p-4 rounded-lg"
              />
              <ListItem
                title="PROJECT DEVELOPMENT"
                text="IC Engineers and Management architecture deliver comprehensive architectural designs that result in the framework and road map for successful projects implementation. Our proven approach and methodologies focus on leveraging your existing strengths, capabilities, and products to increase cost effectiveness and maximize your return on investment."
                className="shadow-lg p-4 rounded-lg"
              />
              <ListItem
                title="PROJECT IMPLEMENTATION"
                text="IC electrical engineers and management implementation services provide you with a team of experts proficient in electricians and management systems to translate, deploy, train and implement your project architecture, helping to ensure the best use of your investments. Using the resources and experience of our engineers and integrators can reduce expenses related to hiring, training, and retaining a specialized workforce."
                className="shadow-lg p-4 rounded-lg"
              />
              <ListItem
                title="PROJECT MANAGEMENT"
                text="IC will create a total solution with defined goals and milestones, a detailed timeline and post installation maintenance and support. Where appropriate, IC will provide turnkey services and project management services."
                className="shadow-lg p-4 rounded-lg"
              />
              <ListItem
                title="SAFETY HEALTH ENVIRONMENT - SHE"
                text="Safety Health Environment is our number one priority, Ideal Choice (Pty) Ltd says No to Unsafe working conditions, unhealthy workers and unfriendly environment."
                className="shadow-lg p-4 rounded-lg"
              />
              <ListItem
                title="TRAINING LEVY"
                text="IDEAL CHOICE (Pty) Ltd is committed to allow for the 0.25% levy in the pricing structure in accordance with the statutory instrument No.139 of 1991 paragraph 9, dated 2 September 1994, all contracts awarded through Public Procurement and Asset Disposal Board will be subject to a training levy of 0.25% of the final contract sum. The levy will be calculated and deducted from the contractor’s interim payment certificates, on gross certified value (less fluctuations only, if applicable)."
                className="shadow-lg p-4 rounded-lg"
              />
            </div>
          </AnimatedElement>
        </div>
        <CertificationInfo/>

      </div>
    </>
  );
};

export default Introduction;
