import React from "react";
import { IconContext } from "react-icons";

const InfoCard = ({ icon, title, brief }) => {
  return (
    <div className="flex flex-col items-center max-w-sm rounded-lg text-center overflow-hidden p-6 bg-white border border-gray-200">
      <IconContext.Provider value={{ className: "react-icons", size: "3em" }}>
        <div className="flex items-center justify-center text-blue-600 bg-gray-100 rounded-full w-[64px] h-[64px] p-4 mb-4">
          {icon}
        </div>
      </IconContext.Provider>
      <div className="font-medium text-xl mb-2">{title}</div>
      <p className="text-gray-700 text-base">{brief}</p>
    </div>
  );
};

export default InfoCard;
