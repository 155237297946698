import React, { useState } from "react";
import audioJungleLogo from "../../src/public/images/partners/Autodesk_Logo.svg.png";
import codeCanyonLogo from "../../src/public/images/partners/Oracle_logo.svg.png";
import cisco from "../../src/public/images/partners/cisco2.png";
import Title from "./Title";

const PartnerLogo = ({ src, alt }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Inline style for the orange dot
  const dotStyle = {
    transitionDelay: isHovered ? "500ms" : "0ms", // Delay the transition
    opacity: isHovered ? 1 : 0, // Appear on hover
  };

  return (
    <div
      className="flex items-center justify-center h-24 w-48 relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={src} alt={alt} className="max-h-full max-w-full" />
      <span
        style={dotStyle}
        className="absolute right-50 bottom-50 h-8 w-8 -z-10 shadow-md shadow-blue-400 bg-blue-600 rounded-full transition-opacity duration-300"
      />
    </div>
  );
};

const Partners = () => {
  
  return (
    <>
    <div className="bg-white py-8">
      <Title title="Partners"/>
         
          <p className="text-gray-600 text-md text-center mt-2 pb-4 lg:px-[25%]">
          At Ideal Choice, we pride ourselves on cultivating strong, strategic partnerships that bolster our service offerings and enhance our customer solutions.
          </p>
      <div className="flex flex-wrap justify-center items-center gap-4 p-4">
        <PartnerLogo src={audioJungleLogo} alt="Autodesk" />
        <PartnerLogo src={cisco} alt="Cisco" />
        <PartnerLogo src={codeCanyonLogo} alt="Oracle" />
      </div>
      </div>
    </>
  );
};

export default Partners;
