
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home"
import About from "./Pages/About"
import Contact from "./Pages/Contact"
import IT from "./Pages/IT"
import Electrical from "./Pages/Electrical"
import Mechanical from "./Pages/Mechanical"
import Projects from "./Pages/Project"


function App() {
  return (
    <BrowserRouter>
      <Routes>
       
          <Route index element={<Home />} />         
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="it" element={<IT/>} />
          <Route path="electrical" element={<Electrical/>} />
          <Route path="mechanical" element={<Mechanical/>} />
          <Route path="projects" element={<Projects/>} />

       
      </Routes>
    </BrowserRouter>
  );
}

export default App;
