import { BsEnvelopeAtFill, BsTelephoneFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";


function Contact({bg}) {
  return (
    <div className="Contact">
      <div className={ `${bg} p-16 mx-8 my-32  items-center rounded-2xl`}>
        <div className="">
          <h1 className="text-3xl text-slate-800 font-semibold">
            We'd Love to hear from you.
          </h1>
          <p className="my-8 text-slate-600">
            We are committed to providing
            exceptional customer service and support. If you are experiencing
            technical difficulties or need assistance with one of our services,
          </p>
        </div>
        <div className="flex   items-center text-slate-800">
          <div className="flex w-1/3 justify-center items-center border-r-2 px-4">
            <div className="flex items-center justify-center text-blue-600 bg-blue-200 rounded-full w-[64px] h-[64px]">
              <BsTelephoneFill size={25} />
            </div>
            <div className="block ml-6">
              <h1 className="text-xl mb-2 font-semibold">Phone Number</h1>
              <div className="block text-slate-600">
                <h1 className="text-xs">+267 3181551</h1>
                <h1 className="text-xs">+267 71604586</h1>
              </div>
            </div>
          </div>
          <div className="flex w-1/3 justify-center items-center border-r-2 px-4">
            <div className="flex items-center justify-center text-blue-600 bg-blue-200 rounded-full w-[64px] h-[64px]">
              <BsEnvelopeAtFill size={25} />
            </div>
            <div className="block ml-6">
              <h1 className="text-xl mb-2 font-semibold">Email Address</h1>
              <div className="block text-slate-600">
                <h1 className="text-xs">sales@idealchoice.co.bw</h1>
              </div>
            </div>
          </div>
          <div className="flex w-1/3 justify-center items-center border-r-2 px-4">
            <div className="flex items-center justify-center text-blue-600 bg-blue-200 rounded-full w-[64px] h-[64px]">
              <MdLocationPin size={25} />
            </div>
            <div className="block ml-6">
              <h1 className="text-xl mb-2 font-semibold">Office Address</h1>
              <div className="block text-slate-600">
                <h1 className="text-xs">
                  Plot 7737,Selokwana Industrial Site,Tlokweng
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex  items-center "></div>
    </div>
  );
}

export default Contact;
