import React, { useState, useEffect } from "react";
import { BannerInfo } from "../data/data";
import { LuRectangleVertical } from "react-icons/lu";

const Banner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState("right");

  const changeSlide = (newIndex) => {
    const isNext = newIndex > currentIndex;
    setSlideDirection(isNext ? "right" : "left");
    setCurrentIndex(newIndex);
  };

  const nextSlider = () => {
    const isLastSlider = currentIndex === BannerInfo.length - 1;
    const newIndex = isLastSlider ? 0 : currentIndex + 1;
    changeSlide(newIndex);
  };

  useEffect(() => {
    const timer = setTimeout(nextSlider, 10000); // Slide every 10 seconds
    return () => clearTimeout(timer);
  }, [currentIndex]);
  const getTextSlideClass = (index) => {
    if (index === currentIndex) {
      return "slide-in-bottom"; // Apply the slide-in animation when the slide is active
    } else {
      return "slide-out-bottom"; // Hide the text when the slide is not active
    }
  };
  return (
    <div className="relative group overflow-hidden ">
      {/* Removed arrows */}

      <div className="overflow-hidden h-[250px] lg:h-[450px] py-8">
        {BannerInfo.map((item, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-all duration-700 ease-in-out ${
              index === currentIndex
                ? "translate-x-0"
                : slideDirection === "right"
                ? "translate-x-full"
                : "-translate-x-full"
            }`}
            style={{
              backgroundImage: `url(${item.backgroundUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="w-full h-full flex items-center justify-start text-left pl-12">
              {/* Apply the text slide class conditionally based on the current index */}
              <div className={`${getTextSlideClass(index)} max-w-lg`}>
                <h1 className="text-2xl md:text-4xl font-bold text-blue-700">
                  {item.title}
                </h1>
                <p className="text-sm md:text-lg text-white">{item.brief}</p>
                <a
                  href={item.cta[0].url}
                  className="inline-block mt-4 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800"
                >
                  {item.cta[0].label}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dots aligned to the bottom-right and stacked vertically */}
      <div className="absolute md:bottom-1/2 bottom-1 right-1/2 md:right-4 flex md:flex-col items-center space-y-1">
        {BannerInfo.map((_, slideIndex) => (
          <LuRectangleVertical
            key={slideIndex}
            size={25}
            onClick={() => changeSlide(slideIndex)}
            className={`cursor-pointer ${
              slideIndex === currentIndex ? "fill-blue-400 stroke-blue-400" : "text-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Banner;
