import Banner2 from "../components/Banner2";
import CallForProjects from "../components/CallForProjects";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Introduction from "../components/ServiceIntro";
import Partners from "../components/Partners";
import ServicesSection from "../components/ServicesSection";

import {
  MdElectricalServices, // For General Electrical Design
  MdConstruction, // For Installation and Maintenance
  MdPower, // For UPS and Powerlines
  MdPowerSettingsNew, // For Standby Generator
  MdTraffic, // For Traffic Lights
  MdSecurity,
  MdVideocam,
  MdNotificationsActive,

} from "react-icons/md";
import { TbCircuitVoltmeter } from "react-icons/tb";
import { GiStreetLight } from "react-icons/gi";
import { FaChargingStation } from "react-icons/fa";
function Electrical() {
  const data = [
    {
      icon: <MdElectricalServices size={50} />,
      title: "General Electrical Design",
      brief:
        "Offering comprehensive electrical design services for a range of applications.",
      direction: "left", // Change direction as needed
    },
    {
      icon: <MdConstruction size={50} />,
      title: "Installation and Maintenance",
      brief:
        "Expert installation and maintenance of electrical systems to ensure safety and efficiency.",
      direction: "bottom", // Change direction as needed
    },
    {
      icon: <MdPower size={50} />,
      title: "UPS",
      brief:
        "Implementing Uninterruptible Power Supplies for continuous power flow.",
      direction: "right", // Change direction as needed
    },
    {
      icon: <MdPowerSettingsNew size={50} />,
      title: "Standby Generator",
      brief:
        "Providing reliable standby generator solutions for uninterrupted power supply.",
      direction: "left", // Change direction as needed
    },
    {
      icon: <MdTraffic size={50} />,
      title: "Traffic Lights",
      brief:
        "Integrating advanced traffic light systems for improved road safety.",
      direction: "bottom", // Change direction as needed
    },
    {
      icon: <TbCircuitVoltmeter size={50} />,
      title: "High Voltage Electrical",
      brief:
        "Specializing in high voltage systems for industrial and commercial needs.",
      direction: "right", // Change direction as needed
    },
    {
      icon: <FaChargingStation size={50} />,
      title: "Powerlines",
      brief:
        "Building and maintaining powerline infrastructure for efficient energy distribution.",
      direction: "left", // Change direction as needed
    },
    // Add more items as necessary
  ];

  const services = [
    // Additions for new services
    {
      icon: <MdSecurity size={50} />,
      title: "Security Devices",
      description:
        "Offering a range of advanced security devices for enhanced safety and protection.",
    },
    {
      icon: <MdVideocam size={50} />,
      title: "CCTV Maintenance",
      description:
        "Providing professional maintenance services to ensure your CCTV systems are functioning effectively.",
    },
    {
      icon: <MdNotificationsActive size={50} />,
      title: "Fire Alarm Systems",
      description:
        "Installing and maintaining state-of-the-art fire alarm systems for maximum safety.",
    },
    {
      icon: <GiStreetLight size={50} />,
      title: "Street Lighting",
      description:
        "Designing and implementing efficient street lighting solutions for public and private spaces.",
    },
    // ...more services
  ];

  const intro = `We specialize in Electrical (General) design, Installations and maintenance,
  (Street Lighting, Traffic Lights, Standby generators, UPS's, High Voltage electrical and power lines), Fire Alarm systems,
  Access Control systems, Security Devices, CCTV Maintenance and High speed data Telecommunications technologies
  and electronics solutions. Ideal Choice provides services to small, medium and large companies. Ideal Choice prides itself
  with its team of qualifed and skilled Technicians and Engineers trained in their respective fields.`;
  return (
    <div className="IT">
      <Header />
      <Banner2 title={"Electrical"} />
      <Introduction title={"Electrical"} data={data} intro={intro} />
      <ServicesSection services={services} title={"Electrical"}/>
      <CallForProjects />
      <Partners />

      <Footer />
    </div>
  );
}

export default Electrical;
