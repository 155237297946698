import React from "react";
import backgroundImage from "../public/images/svg/bg1.svg";

const CallForProjects = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  return (
    <>
      <div
        style={backgroundStyle}
        className="py-32 px-16 bg-slate-800 text-slate-100"
      >
        <div className="text-4xl capitalize font-extrabold">
          Have a Project?
        </div>
        <p className="hidden md:block w-[65%] text-lg mt-4">
          Our expertise spans across diverse domains including Electrical,
          Mechanical, and Information Technology services. With state-of-the-art
          facilities for Calibration, Testing, and Inspection, we excel in
          providing top-notch maintenance solutions. Our team, comprised of
          seasoned professionals in technical and managerial capacities, is
          committed to delivering excellence in every project. Discover how our
          integrated services can elevate your project - contact us today!
        </p>

        <a
          href="tel:+2674535563"
          className="mt-6 inline-block bg-blue-500 text-white px-6 py-4 rounded hover:bg-blue-600 transition-colors duration-300 capitalize text-sm font-medium"
        >
          Call us Now: +267 318 1551
        </a>
      </div>
    </>
  );
};

export default CallForProjects;
