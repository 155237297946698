import Banner2 from "../components/Banner2";
import CallForProjects from "../components/CallForProjects";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Introduction from "../components/ServiceIntro";
import Partners from "../components/Partners";
import ServicesSection from "../components/ServicesSection";

import {
    MdBuild,
    MdAcUnit,
    MdPlumbing,
    MdKitchen,
    MdFireExtinguisher,
    MdSettingsRemote,
    MdEnergySavingsLeaf, MdAir, MdHvac,  MdThermostat,
} from "react-icons/md";
import {BiCool} from "react-icons/bi";

function Mechanical() {
    const data = [
        {
            icon: <MdAcUnit size={50} />,
            title: "HVAC Systems",
            brief: "Providing optimal climate control solutions for comfort and energy efficiency.",
            direction: "left",
        },
        {
            icon: <MdPlumbing size={50} />,
            title: "Plumbing and Piping",
            brief: "Ensuring efficient water and gas flow with expert plumbing services.",
            direction: "bottom",
        },
        {
            icon: <MdKitchen size={50} />,
            title: "Refrigeration",
            brief: "Installing and maintaining high-performance refrigeration systems for various applications.",
            direction: "right",
        },
        {
            icon: <MdFireExtinguisher size={50} />,
            title: "Fire Protection Systems",
            brief: "Enhancing safety with advanced fire detection and suppression technologies.",
            direction: "left",
        },
        {
            icon: <MdSettingsRemote size={50} />,
            title: "Building Automation",
            brief: "Integrating smart automation systems for enhanced building management.",
            direction: "bottom",
        },
        {
            icon: <MdEnergySavingsLeaf size={50} />,
            title: "Energy Management",
            brief: "Optimizing energy usage with sustainable and efficient solutions.",
            direction: "right",
        },
        {
            icon: <MdAir size={50} />,
            title: "Indoor Air Quality",
            brief: "Improving air quality with tailored filtration and ventilation solutions.",
            direction: "left",
        },
        {
            icon: <MdThermostat size={50} />,
            title: "Thermal Insulation",
            brief: "Implementing effective insulation to reduce energy costs and enhance comfort.",
            direction: "bottom",
        },



    ];


    const services = [
        // ... existing services

        {
            icon: <MdBuild size={50} />,
            title: "Hardware Support and Maintenance",
            description:
                "Ensuring your hardware systems are running optimally with expert support and regular maintenance.",
        },
        {
            icon: <BiCool size={50} />,
            title: "Chillers and Cooling Towers",
            description: "Providing large-scale cooling solutions for industrial and commercial settings.",
        },
        {
            icon: <MdHvac size={50} />,
            title: "Ductwork and Ventilation",
            description: "Designing and maintaining air duct systems for optimal ventilation.",

        },

        // ...more services
    ]
    const intro= `We specialize in Mechanical services, sales, support, Mechanical Consultancy,
  Enterprise and Corporate Hardware maintenance,
  Consumables, Mechanical supply and
  installation. We provides services to small, medium and large
  organizations. We have a team of professional consultants
  specializing in HVAC Systems and Building Automation
  solutions.`
    return (
        <div className="Mechanical">
            <Header />
            <Banner2 title={"Mechanical"}/>
            <Introduction title={"Mechanical"} data={data} intro={intro}/>
            <ServicesSection services={services} title={"Mechanical"}/>
            <CallForProjects />
            <Partners />
            <Footer />
        </div>
    );
}

export default Mechanical;
