import React from "react";
import ContactHeader from "./ContactHeader";
import TopNav from "./TopNav";
import Logo from "../components/logo";


const Header = () => {
  return (
    <>
      <div className="relative sticky top-0 z-50">
        <div className="absolute top-10 left-16 z-50 hidden lg:flex">
          <Logo />
        </div>
        <div className="  border-b-2 border-gray-300   text-slate-700 text-xs">
          <ContactHeader />
        </div>
        <div className="sticky top-0 z-40 px-6 md:px-[28px] bg-white ">
          <TopNav />
        </div>
      </div>
    </>
  );
};

export default Header;
