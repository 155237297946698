import Card from "./Card";
import svg from "../public/images/services/electrical/Transformer.jpeg";
import svg2 from "../public/images/services/mechanical/automotive.jpg";
import svg3 from "../public/images/services/it/cabinet.jpeg";
import Title from "./Title";

const Objectives = (props) => {
  return (
    <>
   
      <div className="relative bg-gradient-to-t from-slate-50 to-slate-50">
        <svg
          id="wave"
          style={{ transform: "rotate(0deg)", transition: "0.3s" }}
          viewBox="0 0 1440 490"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 z-10"
        >
          <path
            style={{ transform: "translate(0, 0px);", opacity: "1" }}
            fill="rgb(226 232 240)"
            d="M0,196L60,163.3C120,131,240,65,360,65.3C480,65,600,131,720,130.7C840,131,960,65,1080,32.7C1200,0,1320,0,1440,16.3C1560,33,1680,65,1800,65.3C1920,65,2040,33,2160,32.7C2280,33,2400,65,2520,138.8C2640,212,2760,327,2880,351.2C3000,376,3120,310,3240,245C3360,180,3480,114,3600,81.7C3720,49,3840,49,3960,98C4080,147,4200,245,4320,245C4440,245,4560,147,4680,106.2C4800,65,4920,82,5040,138.8C5160,196,5280,294,5400,326.7C5520,359,5640,327,5760,326.7C5880,327,6000,359,6120,383.8C6240,408,6360,425,6480,400.2C6600,376,6720,310,6840,302.2C6960,294,7080,343,7200,310.3C7320,278,7440,163,7560,138.8C7680,114,7800,180,7920,245C8040,310,8160,376,8280,400.2C8400,425,8520,408,8580,400.2L8640,392L8640,490L8580,490C8520,490,8400,490,8280,490C8160,490,8040,490,7920,490C7800,490,7680,490,7560,490C7440,490,7320,490,7200,490C7080,490,6960,490,6840,490C6720,490,6600,490,6480,490C6360,490,6240,490,6120,490C6000,490,5880,490,5760,490C5640,490,5520,490,5400,490C5280,490,5160,490,5040,490C4920,490,4800,490,4680,490C4560,490,4440,490,4320,490C4200,490,4080,490,3960,490C3840,490,3720,490,3600,490C3480,490,3360,490,3240,490C3120,490,3000,490,2880,490C2760,490,2640,490,2520,490C2400,490,2280,490,2160,490C2040,490,1920,490,1800,490C1680,490,1560,490,1440,490C1320,490,1200,490,1080,490C960,490,840,490,720,490C600,490,480,490,360,490C240,490,120,490,60,490L0,490Z"
            ></path>
        </svg>
        <div className="z-30 py-8 px-4 md:px-8">
          <Title title={"Services"}/>
          <h2 className="font-extrabold italic text-gray-800 text-[32px] md:text-[48px] text-center ">
            Our Core <span className="text-blue-600">Services</span>
          </h2>
          <p className="text-gray-600 text-md text-center mt-2 pb-4 lg:px-[25%] text-justify md:text-center">
            Our  Services are designed to meet the critical needs of our clients with precision and excellence.
             From innovative solutions to enduring support, we ensure every interaction adds tangible value.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:p-16 text-slate-700">
            <div className="colspan-1 z-30">
              <Card svg={svg} title={"Electrical"} brief={"Specializing in comprehensive electrical solutions, from installations and maintenance to advanced system designs, ensuring safety, efficiency, and reliability in all electrical projects."}/>
            </div>{" "}
            <div className="colspan-1 z-30">
            <Card svg={svg2} title={"Mechanical"} brief={"Providing expert mechanical engineering services, including HVAC systems, machinery installation, and maintenance, focused on optimizing performance and durability in various mechanical applications."}/>
            </div>{" "}
            <div className="colspan-1 z-30">
            <Card svg={svg3} title={"Information Technology"} brief={"Delivering cutting-edge IT solutions, from network infrastructure to software development and data management, tailored to meet the evolving digital needs of businesses."}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Objectives;
