import React from 'react';

// Transcribed data from the image into an array of objects
const equipmentList = [
    // TRUCKS & JCB
    { category: "TRUCKS & JCB", description: "JCB 3CX Backhoe Loader", make: "JCB 3CX 4X4SMT", qty: 1, year: 2009, owned: "Owned", reg: "B899APN" },
    { category: "TRUCKS & JCB", description: "Crane Truck UD80 8 ton", make: "Nissan UD80", qty: 1, year: 2008, owned: "Owned", reg: "B548BAY" },
    { category: "TRUCKS & JCB", description: "Crane Truck Hino 8 ton", make: "Hino 500", qty: 1, year: 2002, owned: "Owned", reg: "B962AOD" },
    { category: "TRUCKS & JCB", description: "Truck 4.5 ton", make: "Nissan Cabster", qty: 1, year: 1998, owned: "Owned", reg: "B621ADD" },
    { category: "TRUCKS & JCB", description: "Truck 6x2 Horse", make: "ERF MAN", qty: 1, year: 2004, owned: "Owned", reg: "B313ASA" },
    { category: "TRUCKS & JCB", description: "Crane Truck TATA Novus 3134", make: "TATA Novus", qty: 1, year: 2007, owned: "Owned", reg: "B891BKF" },
    
    // BAKKIES & VANS
    { category: "BAKKIES & VANS", description: "Nissan Bakkie", make: "Np300 2.5D", qty: 1, year: 2014, owned: "Owned", reg: "B214AZE" },
    { category: "BAKKIES & VANS", description: "Toyota Bakkie", make: "Hilux VVTI2.0", qty: 1, year: 2013, owned: "Owned", reg: "B123AWX" },
    { category: "BAKKIES & VANS", description: "Nissan Bakkie", make: "NP300 Bakkie", qty: 1, year: 2008, owned: "Owned", reg: "B716AOE" },
    { category: "BAKKIES & VANS", description: "Nissan Bakkie", make: "Nissan NP300", qty: 1, year: 2015, owned: "Owned", reg: "B240BBT" },
    { category: "BAKKIES & VANS", description: "VW Polo", make: "VW Polo 1.6", qty: 1, year: 2011, owned: "Owned", reg: "B936ATP" },
    { category: "BAKKIES & VANS", description: "Double Axle Trailer", make: "Trail quip 2000", qty: 1, year: 2014, owned: "Owned", reg: "B522AYB" },
    
    // TOOLS & EQUIPMENTS
  { category: "TOOLS & EQUIPMENTS", description: "Cable Jack – Truck pulled", make: "-", qty: 2, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Compactor", make: "Wacker", qty: 6, year: 2010, owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "T1800 Analogue Insulation Tester", make: "Fluke", qty: 3, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Digital Multi-meter", make: "Fluke", qty: 5, year: 2012, owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Phase Rotation Tester", make: "Kyoritsu", qty: 2, year: 2014, owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Hex Crimping Tool 6-120mm", make: "-", qty: 2, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "AC Digital Clamp Meter", make: "Fluke", qty: 8, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Ladder Extension DHA1290", make: "-", qty: 10, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Heavy-duty cable puller", make: "Yale", qty: 3, year: 2014, owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Come along", make: "Yale", qty: 4, year: 2014, owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Ratchet lever hoist 1ton", make: "Yale", qty: 4, year: 2014, owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Chainblock750kg", make: "Yale", qty: 4, year: 2014, owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Heavy-duty Step ladder 11M Long", make: "-", qty: 6, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Conduit Bender 20mm BOSAL", make: "-", qty: 10, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Conduit Bender 25mm BOSAL", make: "-", qty: 15, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Conduit Bender 32mm BOSAL", make: "-", qty: 3, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Conduit Pipe Vice Stand", make: "-", qty: 12, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Conduit 20mm-25mm Stock+Dieset", make: "-", qty: 8, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Conduit Pipe Stand Only", make: "-", qty: 8, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Earth/Polarity Tester", make: "Fluke", qty: 9, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Digital Earth Resistance Tester", make: "Fluke", qty: 10, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Generators", make: "Honda", qty: 7, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Heavy Hammer Drill", make: "HILTI TE56", qty: 10, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Hammer Drill", make: "Bosch", qty: 5, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Heavy Duty Grinder", make: "Bosch", qty: 7, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Baby Grinder", make: "Makita", qty: 10, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Tool Box complete with hand tools", make: "-", qty: 7, year: "-", owned: "Owned", reg: "-" },
  { category: "TOOLS & EQUIPMENTS", description: "Storage container 12 feet used as Mobile storage", make: "-", qty: 2, year: "-", owned: "Owned", reg: "-" }
  ];
  

const EquipmentList = () => {
 
  return (
    <div className="p-5 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold text-gray-800 mb-5 text-center">Plant and Equipment</h1>
      <p className="mb-5 text-gray-600 text-center">The following are lists of major items of relevant equipment that we presently own.</p>
      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-full text-sm divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left font-semibold text-gray-700">Description</th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700">Make</th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700">Qty</th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700">Year</th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700">Owned</th>
              <th className="px-6 py-3 text-left font-semibold text-gray-700">Reg</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {equipmentList.map((item, index) => (
              <tr key={index} className={`hover:bg-gray-50 ${index % 2 === 0 ? '' : 'bg-gray-50'}`}>
                <td className="px-6 py-4 text-gray-900">{item.description}</td>
                <td className="px-6 py-4 text-gray-900">{item.make}</td>
                <td className="px-6 py-4 text-gray-900">{item.qty}</td>
                <td className="px-6 py-4 text-gray-900">{item.year}</td>
                <td className="px-6 py-4 text-gray-900">{item.owned}</td>
                <td className="px-6 py-4 text-gray-900">{item.reg}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EquipmentList;
