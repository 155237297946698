// LatestProjects.js
import React from "react";
import imageUrl from "../public/images/mechanic.jpg";
import Title from "./Title";

const ProjectCard = ({
  employer,
  contactPerson,
  description,
  valueOfWork,
  dateCompleted,
}) => {
  return (
    <div className="relative border rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300">
      {/* Assume an image is associated with each project for visual appeal */}
      <img className="w-full" src={imageUrl} alt={employer} />

      {/* Overlay that appears on hover */}
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-6 opacity-0 hover:opacity-100 transition-opacity duration-300">
        <div className="text-white text-center">
          <h3 className="font-semibold text-xl mb-3">{employer}</h3>
          <p>{description}</p>
        </div>
      </div>

      {/* Visible content */}
      <div className="p-6">
        <h3 className="font-semibold text-xl mb-3">{employer}</h3>
        <p className="text-gray-700 text-base">Contact: {contactPerson}</p>
          {/* <p className="text-gray-700 text-base">Value of Work: {valueOfWork}</p>*/}
        <p className="text-gray-700 text-base">
          Date Completed: {dateCompleted}
        </p>
      </div>
    </div>
  );
};

// projectsData.js

const projectData = [
    {
      id: 1,
      employer: 'Botswana Power Corporation',
      contactPerson: 'Mr. T. Baliki, Tel: +267 2440067',
      description: 'Distribution Citizen Contractors Programme',
      valueOfWork: 'Undisclosed',
      dateCompleted: 'On going project',
    },
    {
      id: 2,
      employer: 'Kota Construction',
      contactPerson: 'Mr. John Phiri, Tel: +267 3915602, Cell: +267 71301462',
      description: 'Proposed BUAN Development project at Sebele Content farm in Gaborone - supply and installation of electrical services and related external works.',
      valueOfWork: 'BWP 10,549,589.00',
      dateCompleted: 'On hold',
    },
    {
      id: 3,
      employer: 'High-End Investments Pty Ltd',
      contactPerson: 'Mr. Jamin Xie, Tel: +267 73888 689',
      description: 'A works contract for the upgrade of Shakawe water treatment plant and master balancing reservoir (Electrical installation and telemetry)',
      valueOfWork: 'BWP 19,967,311.57',
      dateCompleted: 'DEC 2021',
    },
    {
      id: 4,
      employer: 'Lasco Engineering Consultants',
      contactPerson: 'Mrs. Grace Rankgate, Tel: +267 391 2822',
      description: 'Electrical services installations for proposed new primary hospital at Shakawe-TBJC jv (Tonybase/Ideal Choice joint venture)',
      valueOfWork: 'BWP 50,666,767.78',
      dateCompleted: 'March 2021',
    },
    {
      id: 5,
      employer: 'Kota Construction',
      contactPerson: 'Mr. John Phiri, Tel: +267 3915602, Cell: +267 71301462',
      description: 'A works contract for the construction, completion and maintenance of remaining works for institution of health sciences staff houses at block 6 in Gaborone',
      valueOfWork: 'BWP 3,045,105.10',
      dateCompleted: 'May 2018',
    },
    {
      id: 6,
      employer: 'Ministry of Education & Skills Development',
      contactPerson: 'Mr. Mathwai, Tel: 395 2353',
      description: 'Electrical Services Installation for new Hostels and Staff Houses at Mphuthe JSS at Letlhakeng',
      valueOfWork: 'BWP 8,745,103.05',
      dateCompleted: 'May 2017',
    },
    {
      id: 7,
      employer: 'T&T Painters & Decorations (Pty) Ltd',
      contactPerson: 'Mr. Thabiso Tafila, Cell: +267 71632014',
      description: 'Refurbishment of General Electrical Works for 69 low cost staff houses at ssg camp Maruapula in Gaborone',
      valueOfWork: 'BWP 6,821,450.92',
      dateCompleted: 'November 2015',
    },
    {
      id: 8,
      employer: 'Min of Defence, Justice & Security',
      contactPerson: 'Mrs. Dithabi, Tel: +267 3698200',
      description: 'Renovation of burnt Recruitment hostels, General Electrical Installation and Solar Systems at Otse Police Training College.',
      valueOfWork: 'BWP 1,563,910.00',
      dateCompleted: 'February 2013',
    },
    // ... other projects continue in similar structure
  ];

const LatestProjects = () => {
  return (
    <div className="container mx-auto px-4 bg-white">
      <div className="text-center py-12">
        <Title title={"Projects"} />
        <h2 className="text-[32px] md:text-[32px] font-extrabold italic mb-8">
          Our Latest{" "}
          <span className="text-blue-600 font-bold">Projects</span>
        </h2>
        <p className="text-gray-600 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut aliqua. Ut enim
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  pt-4 pb-8">
        {projectData.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </div>
    </div>
  );
};

export default LatestProjects;
