
import Footer from "../components/Footer";
import Header from "../components/Header";
import Contactus from "../components/Contactus";


function Contact() {
  return (
    <div className="Contact">
      <Header />
      <Contactus bg={"bg-slate-100"} />


      <div className="flex  items-center "></div>
      <Footer />
    </div>
  );
}

export default Contact;
