import image from "../public/images/services/mechanical/automotive.jpg";
import image2 from "../public/images/services/mechanical/mech.jpg";



import backgroundImage from "../public/images/backgrounds/bgn-industries.png";

const ITBrief = (props) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  return (
    <>
      <div  style={backgroundStyle} className="grid grid-cols-12  lg:gap-8 p-4 lg:p-8 lg:p-16">
        <div className="relative mb-16 lg:mb-1 order-2 lg:order-1 grid grid-cols-12 gap-6 col-span-12 h-[100px] p-8 lg:h-[350px]  rounded-lg w-full lg:col-span-5 flex justify-center lg:justify-start">
          <div
            className="col-span-4 lg:col-span-7  h-[100px] lg:h-full  w-full  rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div
            className="col-span-4  lg:col-span-5  h-[100px] lg:h-full  w-full   rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image2})` }}
          ></div>

          <div className="hidden w-full lg:absolute bottom-32 h-6 bg-white "></div>
        </div>
        <div className="flex items-center col-span-12 lg:col-span-7 ">
          <div className="">
            <div className="font-semibold text-xl md:text-3xl capitalize text-slate-800">
              Our managed <span className="text-blue-600">Mechanical services</span> let you concentrate on what matters{" "}
            </div>
            <p className="text-md mt-2 text-slate-800 lg:pr-16 text-justify">
            Our Managed Mechanical Services are the key to unlocking your business's potential.
             When operational hitches and technical snags divert your attention from your business goals, 
             our expert team steps in to smooth out the wrinkles. 
             With Ideal Choice, you can redirect your focus to growth and 
             innovation while we ensure your mechanical systems are running at peak efficiency.
             Partner with us for a streamlined operation that propels you ahead of the competition.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ITBrief;
