import Banner2 from "../components/Banner2";
import CallForProjects from "../components/CallForProjects";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Introduction from "../components/ServiceIntro";
import Partners from "../components/Partners";
import ServicesSection from "../components/ServicesSection";

import {
  MdStorage,
  MdVpnKey,
  MdRouter,
  MdSettingsEthernet,
  MdMemory,
  MdCode,
  MdComputer,
  MdBuild,
  MdSecurity,
  MdHowToVote,
} from "react-icons/md";

function IT() {
  const data = [
    {
      icon: <MdSecurity size={50} />,
      title: "CCTV",
      brief:
        "Implementing advanced surveillance systems for enhanced security.",
      direction: "left",
    },
    {
      icon: <MdStorage size={50} />,
      title: "Data Warehousing",
      brief:
        "Integrating robust data warehousing solutions for business intelligence.",
      direction: "bottom",
    },
    {
      icon: <MdMemory size={50} />,
      title: "Database Administration",
      brief: "Maintaining database integrity and optimizing performance.",
      direction: "right",
    },
    {
      icon: <MdVpnKey size={50} />,
      title: "Access Control Systems",
      brief:
        "Securing premises with state-of-the-art access control technologies.",
      direction: "left",
    },
    {
      icon: <MdRouter size={50} />,
      title: "IOT",
      brief: "Enabling interconnected devices for smart automation solutions.",
      direction: "bottom",
    },
    {
      icon: <MdSettingsEthernet size={50} />,
      title: "Networking",
      brief:
        "Designing and deploying reliable network infrastructures for seamless connectivity.",
      direction: "right",
    },
    {
      icon: <MdBuild size={50} />,
      title: "Structured Cabling",
      brief:
        "Establishing comprehensive cabling frameworks for various communication needs.",
      direction: "left",
    },
    {
      icon: <MdCode size={50} />,
      title: "Software Development",
      brief:
        "Crafting custom software solutions to drive innovation and efficiency.",
      direction: "right", // Change direction as needed
    },
    // Add more items as necessary
  ];

  const services = [
    // ... existing services
    {
      icon: <MdComputer size={50} />,
      title: "Computer Sales and Consumables",
      description:
        "Providing top-quality computing products and supplies tailored to your needs.",
    },
    {
      icon: <MdBuild size={50} />,
      title: "Hardware Support and Maintenance",
      description:
        "Ensuring your hardware systems are running optimally with expert support and regular maintenance.",
    },
    {
      icon: <MdSecurity size={50} />,
      title: "Security Audits",
      description:
        "Conducting thorough security assessments to protect your digital assets from threats.",
    },
    {
      icon: <MdHowToVote size={50} />,
      title: "Vendor Selection",
      description:
        "Assisting you in selecting the right vendors to supply quality products and services for your business operations.",
    },
    // ...more services
  ]
  const intro= `We specialize in IT services, sales, support, IT Consultancy,
  Enterprise and Corporate Hardware and Software maintenance,
  Oracle Technologies, Consumables, Networking supply and
  installation. We provides services to small, medium and large
  organizations. We have a team of professional consultants
  specializing in Oracle Database and Business Intelligence
  solutions.`
  return (
    <div className="IT">
      <Header />
      <Banner2 title={"Information Technology"}/>
      <Introduction title={"Information Technology"} data={data} intro={intro}/>
      <ServicesSection services={services} title={"Information Technology"}/>
      <CallForProjects />
      <Partners />

      <Footer />
    </div>
  );
}

export default IT;
