import React from "react";
import svg from "../../src/public/images/svg/bg1.svg";
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const Statistic = ({ start, end, suffix, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="flex justify-center items-center w-full px-4 md:w-1/4 py-2" ref={ref}>
      <div className="text-center">
        <p className="text-xl md:text-4xl font-normal">
          {inView && <CountUp end={end} duration={2} />} 
          <span className="md:text-2xl text-lg">{suffix}</span>
        </p>
        <p className="text-sm md:text-lg mt-2">{text}</p>
      </div>
    </div>
  );
};

const ContactRibbon = () => {
  let BackgroundStyle = {
    backgroundImage: `url(${svg})`,
  };

  return (
    <div style={BackgroundStyle} className="flex items-center text-slate-900 flex-wrap justify-center bg-blue-600 text-white py-9">
      <Statistic start={0} end={25} suffix="Years" text="Proven Track Record" />
      <Statistic start={0} end={98} suffix="%" text="Customer Satisfaction" />
      <Statistic start={0} end={1500} text="Projects Completed" />
      <Statistic start={0} end={3} suffix="mins" text="Average Response time" />
    </div>
  );
};

export default ContactRibbon;
