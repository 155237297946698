import React from "react";

const Vision = () => {
  return (
    <>
   <div className="grid grid-cols-1 md:grid-cols-2 gap-16 text-sm bg-blue-600 text-white p-8 mt-16">
    <div className="text-center">
        <h1 className="text-2xl font-medium text-blue-400 mb-2">Vision</h1>
        <p className="lg:text-md">
        Provide complete and innovative Electrical,IT and Mechanical services of
              unsurpassed quality and value for our clients.
        </p>
    </div>
    <div className="text-center">
        <h1 className="text-2xl font-medium text-blue-400 mb-2">Mission</h1>
        <p className="lg:text-md">
        IDEAL CHOICE (Pty) Ltd strives for business excellence by
              developing cutting edge expertise and instant access to a
              comprehensive range of specialized Electrical,IT and Mechanical
              solutions, as part of our innovative approach.
        </p>
    </div>
</div>


    </>
  );
};

export default Vision;
