import image from "../public/images/services/it/Cable.jpeg";
import image2 from "../public/images/services/it/cabinet.jpeg";
import image3 from "../public/images/services/it/Fluke.jpeg";
import image4 from "../public/images/services/it/Fibre.jpeg";

import ListItem from "./ListItem";
import backgroundImage from "../public/images/backgrounds/bgn-industries.png";

const Policy = (props) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  return (
    <>
      <div
        style={backgroundStyle}
        className="grid grid-cols-12 gap-4 lg:gap-8 py-8 px-8 lg:p-16"
      >
        <div className="col-span-12 lg:col-span-7 lg:mt-16">
          <div className="font-semibold text-xl md:text-3xl  capitalize text-slate-800">
            Benefit of our <span className="text-blue-600">IT services</span>{" "}
            {""}
            Provided by Ideal Choice
          </div>
          <p className="text-md mt-2 text-slate-800 md:pr-16 text-justify ">
          We specialize in IT Infrastructure design, installation, and maintenance.
           Ideal Choice delivers top-tier services to small, medium, and large companies.
            Our team is comprised of highly qualified and skilled IT professionals, including technicians 
            and engineers, each expertly trained in their respective areas of information technology. 
            From network setup to cybersecurity, software development, and system optimization,
           we ensure all your IT needs are met with precision and efficiency.
          </p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-6 py-8 text-slate-700">
            <div>
              <ListItem
                title="Cutting-edge IT Solutions for Modern Businesses"
                text="Delivering top-tier IT services designed to streamline your operations, enhance security, and drive innovation, ensuring your business stays ahead of the technological curve."
              />
            </div>

            <div>
              <ListItem
                title="Advanced Access Control Systems"
                text="Implementing state-of-the-art access control systems to secure your premises and data, ensuring controlled access and enhanced security protocols."
              />
            </div>

            <div>
              <ListItem
                title="Expert Software Development and Integration"
                text="Crafting custom software solutions that integrate seamlessly with your existing operations, boosting efficiency and creating a cohesive technology ecosystem."
              />
            </div>

            <div>
              <ListItem
                title="Networking Solutions for Seamless Connectivity"
                text="Providing comprehensive networking services, from infrastructure setup to optimization, ensuring reliable and high-speed connectivity for your business operations."
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 col-span-12 h-[100px] px-4  lg:p-8 lg:h-[600px]  rounded-lg w-full lg:col-span-5 flex justify-center lg:justify-start">
          <div
            className="col-span-4 lg:col-span-7  h-[100px]  w-full lg:h-full rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div
            className="col-span-4  lg:col-span-5  h-[100px]  w-full lg:h-full   rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image2})` }}
          ></div>

          <div
            className="col-span-4 lg:col-span-4  h-[100px]  w-full lg:h-full  rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image3})` }}
          ></div>
          <div
            className="hidden lg:block col-span-4  lg:col-span-8  h-[100px]  w-full lg:h-full   rounded-lg bg-center bg-cover bg-no-repeat flex justify-center "
            style={{ backgroundImage: `url(${image4})` }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Policy;
