import React from "react";

const Title = (props) => {
  return (
    <>    
    <div className="flex justify-center items-center self-center mb-8">
    <div className="flex justify-center items-center self-center h-[8px] w-[8px] mx-2 shadow-md shadow-blue-400 border-0 bg-blue-600 "></div> 

<div className="text-lg capitalize text-slate-900 font-normal">
  <h1>
      {props.title}
  </h1>
</div>
      </div>   
    
    </>
  );
};

Title.defaultProps = {
   
    title:"Title"
};

export default Title;
