import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Introduction from "../components/AboutIntro";

import Partners from "../components/Partners";
import EquipmentList from "../components/Equipments";
function Home() {
  
  return (
    <div className="Home">
      <Header />
      <Banner />
      <Introduction />

      <EquipmentList/>
      <Partners/>
      <Footer />
    </div>
  );
}

export default Home;
