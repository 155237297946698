import React from "react";
import InfoCard from "./InfoCard";
import backgroundImage from "../public/images/backgrounds/bgn-industries.png";
import Title from "./Title";
import { MdElectricBolt, MdBuild, MdComputer } from "react-icons/md";
import { useInView } from "react-intersection-observer";

const AnimatedElement = ({ children, direction = "left" }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animationClass = inView
    ? `slide-in-${direction}`
    : `slide-out-${direction}`;

  return (
    <div ref={ref} className={animationClass}>
      {children}
    </div>
  );
};

const Introduction = ({vector}) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <>
      <div
        style={backgroundStyle}
        className="px-8 lg:px-8 py-16 lg:py-32 text-slate-900 bg-gradient-to-b from-slate-100"
      >
        <div className="w-full ">
          <AnimatedElement direction="left">
            <Title title={"Introduction"} />
          </AnimatedElement>
          <AnimatedElement direction="bottom">
            <div className="text-left">
              <h2 className="text-[32px] md:text-[58px] font-extrabold italic mb-8">
                Success Through Strategic{" "}
                <span className="text-blue-600 font-bold">Partnerships</span>
              </h2>
              <p className="text-md md:text-lg text-gray-700 mb-8 text-justify">
                Ideal Choice (Pty) Ltd is a 100% Citizen-owned company
                specializing in Information Technology Services and Electrical
                Services. Our philosophy and strategy revolve around "success
                through strategic partnerships." We have forged key
                international partnerships, providing us with the necessary
                technical and logistical capabilities to excel in medium to
                large-scale ICT implementations and electrical solutions.
              </p>
            </div>
          </AnimatedElement>
        </div>
        <div className="block md:flex justify-center items-center md:mt-16 gap-4">
          <AnimatedElement direction="left">
            <InfoCard
              icon={<MdElectricBolt size={50} />}
              title="Electrical Services"
              brief="Ensuring reliable and safe electrical systems operation."
            />
          </AnimatedElement>
          <AnimatedElement direction="bottom">
            <InfoCard
              icon={<MdBuild size={50} />}
              title="Mechanical Services"
              brief="Providing expert mechanical engineering solutions and services."
            />
          </AnimatedElement>
          <AnimatedElement direction="right">
            <InfoCard
              icon={<MdComputer size={50} />}
              title="Information Technology"
              brief="Delivering cutting-edge IT solutions and support for businesses."
            />
          </AnimatedElement>
        </div>
      </div>
    </>
  );
};

export default Introduction;
