import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";

const ListItem = (props) => {
    if(!props.text) return null
  return (
    <>
      <li className="flex -mx-5 items-start text-left mt-8 md: py-1">
        <div className="rounded-full text-white bg-blue-600  flex justify-center  ">
          <BsCheckCircleFill size={25} />
        </div>
        <p className="mx-2 mr-8 flex -mt-1 font-medium text-xl text-slate-800 ">
          {props.title}
        </p>
      </li>
      <p className="mx-3 flex  text-left">
          {props.text}
        </p>
    </>
  );
};

export default ListItem;
